var download = {
	el: {
		downloadBtn: $('.js-download'),
	},

	events: function () {
		this.el.downloadBtn.on('click', function() {
			var _this = $(this);
				endpoint = _this.data('download-endpoint');
				defaultMessage = _this.find('.list__download-default'),
				loadingMessage = _this.find('.list__download-loading'),
				successMessage = _this.find('.list__download-success'),
				errorMessage = _this.find('.list__download-error');

			defaultMessage.hide()
			loadingMessage.css('display', 'flex')
			_this.attr('disabled', '')
			_this.addClass('list__download--active')

			$.ajax({
				url: endpoint,
				type: "GET",
				success: function() {
					loadingMessage.hide()
					successMessage.css('display', 'flex')
				},
				error: function(error) {
					loadingMessage.hide()
					errorMessage.css('display', 'flex')
				}
			});
			
			
		})
	},

	init: function () {
		this.events()
	}
}

download.init()